import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TestimonialVideo from '../../components/TestimonialVideo';

const TestimonialVideoArea = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "images/testimonials-video/porque-mais-militar.png" }) {
        childImageSharp {
          fluid(maxHeight: 355, maxWidth: 570, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `);

  const { backgroundImage } = data;
  const testimonialVideoTitle = 'Curso Preparatório On-Line focado em <span>Concursos Militares</span>';
  const testimonialVideoSubtitle = 'Prepare-se com quem realmente aprova!';
  const testimonialVideoItems = [
    {
      videoLink: 'https://www.youtube.com/watch?v=oLif8AlAUmg',
      videoImage: backgroundImage.childImageSharp,
    },
  ];
  return (
    <TestimonialVideo
      title={testimonialVideoTitle}
      subtitle={testimonialVideoSubtitle}
      videoItems={testimonialVideoItems}
    />
  );
};

export default TestimonialVideoArea;
